body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

  .bg-orange {
    background: #ef6c00;
  }

  .pull-right {
      float: right;
  }

  .brand-logo {
      font-size: 30px;
      color: white;
  }
  .navbar-light .navbar-nav .nav-link   .navbar-brand{
      color: white;
  }
  .navbar-light .navbar-brand {
    color: white!important;
  }

  .navbar a {
      padding: 10px;
      color: white;
  }
  .navbar a:hover {
    color: #ef6c00;
    background: white;
    text-decoration: underline;
  }


  @media only screen and (max-width: 992px){
        nav .brand-logo {
            font-size: 20px;
            left: 20%;
          
        }
  }
body, * {
    font-family: sans-serif;
    color: #797575;
}




.card-panel{
    color: white;
    padding: 10px!important;
}

.center {
    text-align: center;
}

h3{
    padding: 20px;
}

.playertable th, .playertable td {
    border-top: 0px!important;
    border-bottom: 0px!important;
}

.option-card {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 15px;
   
    margin: 2px;
}
.option-card-header{
    padding: 0px!important;
    background: navajowhite;
    border: 1px solid #ccc;
    color: #ef6c00;
    font-weight: 800;

}

.inline-block {
    display: inline-block!important;
}

.inline-block-no-side-padding {
    display: inline-block!important;
    padding-right: 0px;
    padding-left: 0px;
}

.option-card-body {
    padding-right: 0px;
    padding-left: 0px;
}
.option-card-body > div {
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px;
}
.option-card-body > div:nth-child(3),.option-card-body > div:nth-child(3),.option-card-body > div:nth-child(6){
    text-align: center;
}

.option-card-body > div:nth-child(6){
    background: #eee;
    font-weight: 800;
}

.white-border {
    border: 1px solid white!important;
}

.about-image {
    border-radius: 30px;
    width: 200px;
    height: 250px;
    border: 2px solid #ccc;
}

.display-none {
    display: none;
}

.aboutrow {
    font-size: 18px;
}
.aboutrow div, .home div {
    margin-top: 15px;   
}
.home  div {
   
    text-align: justify;
}
.home  div.center {
    text-align: center;
}
.home  div.left {
    text-align: left;
}
.home  div.right {
    text-align: right;
}

.home h4 {
    color: #ef6c00;
    margin-top: 30px;
}


.pointer {
    cursor: pointer;
}



.header-image {
    height: 300px;
    background-image: url(/static/media/header02.53d4d9b2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.mobileinhandimg {
    width: 100%;
 
}

.orangebtn {
    background-color: #ef6c00;
    color: white;
    border: 1px solid #ef6c00;
    display: inline!important;
    padding: 10px;
}
.orangebtn:hover {
    background-color: white;
    color:#ef6c00;
}

.justify {
    text-align: justify;
}

.textleft {
    text-align: left;
}
.textright {
    text-align: right;
}

.generationbox {
    font-size: 15px;
}

.vertical-middle {
    vertical-align: middle;
}

.vertical-top {
    vertical-align: top;
}


.idea {
    border: 1px solid #ccc;
    margin: 2px;
    box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);

}

.idea-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.word-break {
    word-break: break-all;
}

.overflow-x {
    overflow: auto;
}
.display-none {
    display: none;
}

#addnewideabox {
    border: 1px solid #ccc;
    padding: 10px;
    background: #ef6c0040;
}
@media screen and (max-width: 800px) { 
    .navbar-nav li {
        padding-bottom: 20px;
    }
    .header-image {
        height: 100px;
        
    }
}
.footer {
    position: relative;
    width: 100%;
    bottom: 0;
    margin-top: 30px;
}
.footer > div {
    color: white;
    background: #9c9999;
    text-align: center;
    padding: 20px;
    vertical-align: middle;
}
