.footer {
    position: relative;
    width: 100%;
    bottom: 0;
    margin-top: 30px;
}
.footer > div {
    color: white;
    background: #9c9999;
    text-align: center;
    padding: 20px;
    vertical-align: middle;
}