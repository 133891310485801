body, * {
    font-family: sans-serif;
    color: #797575;
}




.card-panel{
    color: white;
    padding: 10px!important;
}

.center {
    text-align: center;
}

h3{
    padding: 20px;
}

.playertable th, .playertable td {
    border-top: 0px!important;
    border-bottom: 0px!important;
}

.option-card {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 15px;
   
    margin: 2px;
}
.option-card-header{
    padding: 0px!important;
    background: navajowhite;
    border: 1px solid #ccc;
    color: #ef6c00;
    font-weight: 800;

}

.inline-block {
    display: inline-block!important;
}

.inline-block-no-side-padding {
    display: inline-block!important;
    padding-right: 0px;
    padding-left: 0px;
}

.option-card-body {
    padding-right: 0px;
    padding-left: 0px;
}
.option-card-body > div {
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 5px;
}
.option-card-body > div:nth-child(3),.option-card-body > div:nth-child(3),.option-card-body > div:nth-child(6){
    text-align: center;
}

.option-card-body > div:nth-child(6){
    background: #eee;
    font-weight: 800;
}

.white-border {
    border: 1px solid white!important;
}

.about-image {
    border-radius: 30px;
    width: 200px;
    height: 250px;
    border: 2px solid #ccc;
}

.display-none {
    display: none;
}

.aboutrow {
    font-size: 18px;
}
.aboutrow div, .home div {
    margin-top: 15px;   
}
.home  div {
   
    text-align: justify;
}
.home  div.center {
    text-align: center;
}
.home  div.left {
    text-align: left;
}
.home  div.right {
    text-align: right;
}

.home h4 {
    color: #ef6c00;
    margin-top: 30px;
}


.pointer {
    cursor: pointer;
}



.header-image {
    height: 300px;
    background-image: url(../images/header02.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.mobileinhandimg {
    width: 100%;
 
}

.orangebtn {
    background-color: #ef6c00;
    color: white;
    border: 1px solid #ef6c00;
    display: inline!important;
    padding: 10px;
}
.orangebtn:hover {
    background-color: white;
    color:#ef6c00;
}

.justify {
    text-align: justify;
}

.textleft {
    text-align: left;
}
.textright {
    text-align: right;
}

.generationbox {
    font-size: 15px;
}

.vertical-middle {
    vertical-align: middle;
}

.vertical-top {
    vertical-align: top;
}


.idea {
    border: 1px solid #ccc;
    margin: 2px;
    -webkit-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 5px 0px rgba(0,0,0,0.75);

}

.idea-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.word-break {
    word-break: break-all;
}

.overflow-x {
    overflow: auto;
}
.display-none {
    display: none;
}

#addnewideabox {
    border: 1px solid #ccc;
    padding: 10px;
    background: #ef6c0040;
}
@media screen and (max-width: 800px) { 
    .navbar-nav li {
        padding-bottom: 20px;
    }
    .header-image {
        height: 100px;
        
    }
}