  .bg-orange {
    background: #ef6c00;
  }

  .pull-right {
      float: right;
  }

  .brand-logo {
      font-size: 30px;
      color: white;
  }
  .navbar-light .navbar-nav .nav-link   .navbar-brand{
      color: white;
  }
  .navbar-light .navbar-brand {
    color: white!important;
  }

  .navbar a {
      padding: 10px;
      color: white;
  }
  .navbar a:hover {
    color: #ef6c00;
    background: white;
    text-decoration: underline;
  }


  @media only screen and (max-width: 992px){
        nav .brand-logo {
            font-size: 20px;
            left: 20%;
          
        }
  }